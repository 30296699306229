import type {
  IvfTransferDetail,
  MedicalRecordReviewScreen,
  PregnancyDetail,
} from './MedicalRecordReview.interface';

export const initialValues: Omit<MedicalRecordReviewScreen, 'id'> = {
  previous_pregnancy_count: 1,
  pregnancy_details: [],
  previous_ivf_transfer_count: 0,
  ivf_transfers_details: [],
  // NOTE: API returns this as an array, but should be an object
  // There will be only one element in the array
  ob_gyn_details: [
    {
      doctor_first_name: null,
      doctor_middle_name: null,
      doctor_last_name: null,
      doctor_phone_number: null,
      doctor_clinic_name: null,
      doctor_clinic_address: {
        line1: null,
        line2: null,
        city: null,
        state: null,
        zipcode: null,
        location: null,
      },
    },
  ],
};

export const pregnancyDetailInitialValues: PregnancyDetail = {
  same_doctor_details_as_first: false,
  delivery_date: null,
  doctor_first_name: null,
  doctor_middle_name: null,
  doctor_last_name: null,
  doctor_phone_number: null,
  doctor_clinic_name: null,
  doctor_clinic_country: null,
  doctor_clinic_address: {
    line1: null,
    line2: null,
    city: null,
    state: null,
    zipcode: null,
    location: null,
  },
  doctor_clinic_international_address: null,
  facility_type: null,
  delivery_address: {
    line1: null,
    line2: null,
    city: null,
    state: null,
    zipcode: null,
    location: null,
  },
  delivery_international_address: null,
};

export const ivfTransferInitialValues: IvfTransferDetail = {
  same_doctor_details_as_first: false,
  transfer_date: null,
  doctor_first_name: null,
  doctor_middle_name: null,
  doctor_last_name: null,
  doctor_phone_number: null,
  doctor_clinic_name: null,
  doctor_clinic_address: {
    line1: null,
    line2: null,
    city: null,
    state: null,
    zipcode: null,
    location: null,
  },
};
