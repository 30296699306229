import { apiEnums, useApiClient } from '@nodal/api';
import { useCallback, useContext, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';

import { queryKeys } from 'consts/query';

import { ScreeningContext } from './ScreeningContextProvider';

import type {
  FormSubmitHandler,
  ScreeningProviderProps,
} from './Screening.interface';
import type { ApiModel } from '@nodal/api';

const getNextStepIndex = (steps: ScreeningProviderProps['steps']) =>
  steps.findIndex(
    (s) =>
      // NOTE: Find the next step that is not completed
      // Half - indicates that the step has been completed with some conditions that must be met later
      s.status !== apiEnums.ScreenStatusEnum.App &&
      s.status !== apiEnums.ScreenStatusEnum.Half,
  );

export const useScreening = <
  SubmitValues extends ApiModel.ScreenContentObject,
>() => {
  const {
    currentStep,
    updateCurrentStep,
    steps,
    updateSteps,
    started,
    updateStarted,
    completed,
    updateCompleted,
  } = useContext(ScreeningContext);
  const apiClient = useApiClient();

  const screensList = useQuery(
    queryKeys.screensList,
    () => apiClient.api.ScreensApi.screensList(),
    { enabled: false },
  );

  const screenSubmit = useMutation(
    (request: ApiModel.ScreensApiScreensSubmitCreateRequest) =>
      apiClient.api.ScreensApi.screensSubmitCreate(request),
  );

  const start = useCallback(() => {
    updateStarted!(true);
  }, [updateStarted]);

  const refresh = useCallback(async () => {
    const { data: steps } = await screensList.refetch();

    if (!steps?.data || !steps?.data.length) {
      return;
    }

    updateSteps!(steps.data);

    const index = getNextStepIndex(steps.data);
    if (index !== -1) {
      updateCurrentStep!(
        steps.data.length > 0
          ? {
              ...steps.data[index],
              index,
            }
          : undefined,
      );
    } else {
      updateCompleted!(true);
    }
  }, [screensList, updateSteps, updateCurrentStep, updateCompleted]);

  const getNextStep = useCallback(() => {
    if (!currentStep) return;

    const nextStep = steps[currentStep.index + 1];

    if (!nextStep) return;

    return {
      ...nextStep,
      index: currentStep.index + 1,
    };
  }, [steps, currentStep]);

  const submit = useCallback<FormSubmitHandler<SubmitValues>>(
    async (data) => {
      if (!currentStep?.id) {
        throw new Error('Screening step id is undefined');
      }

      try {
        const submitResponse = await screenSubmit.mutateAsync({
          id: currentStep.id,
          screenContentObject: data,
        });

        await refresh();

        return submitResponse.data;
      } catch {
        // TODO: Add error handling here
        return undefined;
      }
    },
    [refresh, currentStep, screenSubmit],
  );

  return {
    steps: useMemo(
      () =>
        steps.map(({ title, status }, index) => ({
          title,
          status,
          index,
        })),
      [steps],
    ),
    currentStep,
    start,
    started,
    completed,
    refresh,
    submit,
    updateCurrentStep,
    getNextStep,
  };
};
