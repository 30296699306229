import type { FC, ReactElement } from 'react';

import { insuranceReviewsUids } from '@nodal/core/consts/insuranceReviewsUids';

import { InsuranceReviewDecision } from './InsuranceDecision';
import { InsuranceDetails } from './InsuranceDetails';
import { InsuranceRecords } from './InsuranceRecords';

import type { InsuranceReviewProps } from './InsuranceReview.interface';
import type { InsuranceReviewsUid } from '@nodal/core/consts/insuranceReviewsUids';

const stageUidToElement: {
  [key in InsuranceReviewsUid]: ReactElement | null;
} = {
  [insuranceReviewsUids.donorInsuranceReviewDetails]: <InsuranceDetails />,
  [insuranceReviewsUids.donorInsuranceRecords]: <InsuranceRecords />,
  [insuranceReviewsUids.donorInsuranceReviewDecision]: (
    <InsuranceReviewDecision />
  ),
};

export const InsuranceReview: FC<InsuranceReviewProps> = ({ stages }) => {
  return (
    <div className="flex flex-col gap-4 w-full divide-y divide-grey-forest-100">
      {stages.map(({ uid }) => (
        <div key={uid}>{uid ? stageUidToElement[uid] : null}</div>
      ))}
    </div>
  );
};
