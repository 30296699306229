import type { InsuranceDetailsProps } from './InsuranceDetails.interface';

export const InsuranceDetails = ({ insurance }: InsuranceDetailsProps) => {
  return (
    <div className="flex flex-col gap-8 w-full">
      {insurance.health_insurance && (
        <div className="flex flex-col gap-8">
          <div className="flex flex-row gap-4 items-center pb-4 border-b border-grey-forest-200">
            <p className="text-sm text-center text-grey-forest-700 sm:text-left">
              <span className="font-bold">Health Insurance:</span>{' '}
              {insurance.health_insurance}
            </p>
          </div>
          <div className="flex flex-col gap-8 border-b border-grey-forest-200 lg:flex-row">
            <div className="flex flex-col gap-4 items-center pb-4 border-b border-grey-forest-200 lg:border-none">
              <p className="mb-2 text-sm font-bold text-center text-grey-forest-700 sm:text-left">
                Front Insurance Photo
              </p>
              <div className="w-full max-w-2xl">
                <img
                  src={insurance.insurance_photo_front || ''}
                  alt="Insurance document front"
                  className="w-full rounded-lg"
                />
              </div>
            </div>
            <div className="flex flex-col gap-4 items-center pb-4 border-b border-grey-forest-200 lg:border-none">
              <p className="mb-2 text-sm font-bold text-center text-grey-forest-700 sm:text-left">
                Back Insurance Photo
              </p>
              <div className="w-full max-w-2xl">
                <img
                  src={insurance.insurance_photo_back || ''}
                  alt="Insurance document back"
                  className="w-full rounded-lg"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
