import { useApiClient } from '@nodal/api';
import { UploadedFileList } from '@nodal/uikit/components/UploadedFileList';
import { useQuery } from 'react-query';

import { queryKeys } from 'consts/query';
import { transformDocumentsIntoUploadedFiles } from 'hooks/useUploadInsuranceDocuments';

import { getDetailsForSection, getSectionQuestionsByRole } from '../utils';

import type {
  ParentProfileInformation,
  ProfileData,
  QuestionData,
} from '../MatchProfile.interface';
import type { SectionDetailsProps } from './SectionDetails.interface';

export const InsuranceReviewSectionDetails = ({
  profile,
  role,
}: SectionDetailsProps<ProfileData>) => {
  const questions: QuestionData<ParentProfileInformation>[] =
    getSectionQuestionsByRole('insuranceReview', role);
  const filteredQuestions = questions.filter((q) => q.type !== 'image');
  const imageQuestions = questions.filter(
    (q: QuestionData<ParentProfileInformation>) => q.type === 'image',
  );
  const apiClient = useApiClient();
  const { data: insuranceDocuments } = useQuery(
    queryKeys.insuranceDocumentsList,
    () =>
      apiClient.api.MedicalReviewsApi.medicalReviewsInsuranceDocumentsOwnList(),
  );
  const formattedDocuments = transformDocumentsIntoUploadedFiles(
    insuranceDocuments?.data || [],
  );

  const profileDataWithInsuranceReview = {
    ...profile,
    ...profile.insurance_review,
  };

  const renderValue = (type: string | undefined, value: string | undefined) => {
    switch (type) {
      case 'image':
        return (
          <img
            src={value}
            alt="Insurance document"
            className="max-w-xs rounded-lg"
          />
        );
      case 'checkbox':
        return value === 'no_insurance' || value === null ? 'Yes' : 'No';
      default:
        return value;
    }
  };

  return (
    <div className="flex flex-col w-full">
      {getDetailsForSection(
        profileDataWithInsuranceReview,
        filteredQuestions,
      ).map(({ label, value, type }) => {
        if (!value) return null;

        return (
          <div
            className="flex flex-col gap-2 justify-start items-start py-4 mb-4 border-y border-grey-forest-100 sm:flex-row sm:gap-0 sm:items-center sm:py-3"
            key={label}
          >
            <div className="space-y-2 w-fit">
              <h3 className="text-lg text-grey-forest-900">{label}</h3>
              <div className="text-grey-forest-500">
                {renderValue(type, value)}
              </div>
            </div>
          </div>
        );
      })}
      <div className="flex flex-row flex-wrap gap-4">
        {getDetailsForSection(
          profileDataWithInsuranceReview,
          imageQuestions,
        ).map(({ label, value, type }, index, array) => {
          if (!value) return null;

          return (
            <div
              key={label}
              className={`pr-2 ${
                index !== array.length - 1
                  ? 'border-r-2 border-grey-forest-100'
                  : ''
              }`}
            >
              <div className="space-y-2 w-fit">
                <h3 className="text-lg text-grey-forest-900">{label}</h3>
                <div className="flex flex-row gap-2 text-grey-forest-500">
                  {renderValue(type, value)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {formattedDocuments.length > 0 && (
        <div className="flex flex-col pt-4 w-full">
          <h3 className="text-lg text-grey-forest-900">Insurance Documents</h3>
          <UploadedFileList
            files={formattedDocuments}
            uploaderIconVisible={false}
            hideDeleteIcon
            hideProgress
            onDelete={() => Promise.resolve()}
          />
        </div>
      )}
    </div>
  );
};
