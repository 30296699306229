export const screenTypes = {
  parentsQuestionnaire: 'parents-questionnaire',
  donorQuestionnaire: 'donor-questionnaire',
  stripeIdentity: 'stripe-identity',
  checkr: 'checkr',
  medicalForms: 'medical-forms',
  medicalReview: 'medical-review',
  medicalRecordReview: 'medical-record-review',
  hipaaConsentForm: 'hipaa-consent-form',
  organizationCandidateProfile: 'organization-candidate-profile',
  finalReview: 'final-review',
  parentsMatching: 'parents-matching',
  surrogateMatching: 'surrogate-matching',
  matching: 'matching',
  parentsProfile: 'parents-profile',
  donorProfile: 'donor-profile',
  navigatorProfile: 'navigator-parents-profile',
  requestPayment: 'requestPayment',
  matchingPayment: 'matchingPayment',
  contract: 'contract',
  insuranceReview: 'insurance-review',
} as const;

export type ScreenType = typeof screenTypes[keyof typeof screenTypes];
