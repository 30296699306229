import type { ReactNode } from 'react';

import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { screenTypes } from 'consts/screenTypes';
import { useUser } from 'hooks/useUser';

import type { ScreeningProviderProps } from './Screening.interface';

const initialValues: ScreeningProviderProps = {
  started: false,
  completed: false,
  steps: [],
  currentStep: undefined,
};

export const ScreeningContext = createContext(initialValues);

export const ScreeningContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [currentStep, setCurrentStep] =
    useState<ScreeningProviderProps['currentStep']>();
  const [steps, setSteps] = useState<ScreeningProviderProps['steps']>([]);
  const [started, setStarted] = useState(false);
  const [completed, setCompleted] = useState(false);
  const navigate = useNavigate();
  const { user, isDonor } = useUser();

  const handleNavigation = useCallback(() => {
    if (
      user?.role === 'dnr' &&
      currentStep?.type === screenTypes.insuranceReview &&
      currentStep?.status !== 'pend'
    ) {
      navigate('/app/matching/start');
      return;
    }

    const isBackgroundCheckCompleted = localStorage.getItem(
      'background-check-completed',
    );

    if (
      isBackgroundCheckCompleted &&
      JSON.parse(isBackgroundCheckCompleted).id === user?.id &&
      isDonor &&
      currentStep?.type === screenTypes.checkr &&
      currentStep?.status === 'pend'
    ) {
      localStorage.removeItem('background-check-completed');
      return;
    }

    if (
      isBackgroundCheckCompleted &&
      JSON.parse(isBackgroundCheckCompleted).id === user?.id &&
      isDonor &&
      currentStep?.type === screenTypes.checkr &&
      currentStep?.status !== 'pend'
    ) {
      const nextStep = steps[currentStep.index + 1];

      if (nextStep) {
        setCurrentStep({
          ...nextStep,
          index: currentStep.index + 1,
        });
      }
    }
  }, [currentStep, isDonor, navigate, steps, user?.id, user?.role]);

  useEffect(() => {
    if (currentStep) {
      handleNavigation();
    }
  }, [currentStep, handleNavigation]);

  const updateCurrentStep = useCallback(
    (step: ScreeningProviderProps['currentStep']) => {
      setCurrentStep(step);
    },
    [],
  );

  const updateSteps = useCallback((value: ScreeningProviderProps['steps']) => {
    setSteps(value);
  }, []);

  const updateCompleted = useCallback((value: boolean) => {
    setCompleted(value);
  }, []);

  const updateStarted = useCallback((value: boolean) => {
    setStarted(value);
  }, []);

  const value = useMemo(
    () => ({
      currentStep,
      updateCurrentStep,
      steps,
      updateSteps,
      started,
      updateStarted,
      completed,
      updateCompleted,
    }),
    [
      currentStep,
      updateCurrentStep,
      steps,
      updateSteps,
      completed,
      updateCompleted,
      started,
      updateStarted,
    ],
  );

  return (
    <ScreeningContext.Provider value={value}>
      {children}
    </ScreeningContext.Provider>
  );
};
