import type { FC } from 'react';

import { apiEnums, useApiClient } from '@nodal/api';
import { t } from '@nodal/i18n';
import { Checkbox } from '@nodal/uikit/components/Checkbox';
import { useConfirmationDialog } from '@nodal/uikit/components/ConfirmationDialog';
import { FileDropZone } from '@nodal/uikit/components/FileDropZone';
import { ImageInput } from '@nodal/uikit/components/ImageInput';
import { TextInput } from '@nodal/uikit/components/TextInput';
import { UploadedFileList } from '@nodal/uikit/components/UploadedFileList';
import { useFormikContext } from 'formik';
import { useQuery } from 'react-query';

import { queryKeys } from 'consts/query';
import { useUploadInsuranceDocuments } from 'hooks/useUploadInsuranceDocuments';
import { useUser } from 'hooks/useUser';

import type { ProfileData } from '../MatchProfile.interface';
import type { EditSectionProps } from './EditForm.interface';

type EditInsuranceReviewProps = Omit<
  EditSectionProps<ProfileData>,
  'questions'
>;

export const EditInsuranceReview: FC<EditInsuranceReviewProps> = ({
  values,
}) => {
  const { setFieldValue } = useFormikContext();
  const confirm = useConfirmationDialog();
  const apiClient = useApiClient();
  const { user } = useUser();
  const { data: insuranceDocuments } = useQuery(
    queryKeys.insuranceDocumentsList,
    () =>
      apiClient.api.MedicalReviewsApi.medicalReviewsInsuranceDocumentsOwnList(),
  );

  const { onDelete, onUpload, uploadedFiles, disabled } =
    useUploadInsuranceDocuments({
      documents: insuranceDocuments?.data || [],
      confirm,
      documentType: apiEnums.MedicalDocumentDocumentTypeEnum.SurroInsDoc,
      medicalReviewId: user?.medical_review_id,
    });

  return (
    <div className="flex overflow-y-auto flex-col w-full h-96">
      <p className="p-8 py-4">
        {t(
          'Please provide information below to help us evaluate your health insurance coverage as it relates to surrogacy',
        )}
      </p>
      <div className="flex flex-col gap-x-4 gap-y-10 p-8 py-4 bg-white rounded-lg">
        <Checkbox
          label={t("I don't have health insurance")}
          name="no_insurance"
          onChange={(e) => {
            setFieldValue('no_insurance', e.target.checked);
          }}
        />
        {!values.no_insurance && (
          <>
            <TextInput
              label={t('Who is your health insurance provider?')}
              name="health_insurance"
              type="text"
              required
            />

            <div className="flex flex-col w-full">
              <label className="block text-sm font-medium text-gray-700">
                {t(
                  'Please upload a photo of the front and back of your health insurance card',
                )}
              </label>

              <div className="flex gap-4 justify-between w-full">
                <div className="m-1 w-full text-center">
                  <p className="block mt-1 text-sm text-gray-500">
                    {t('Front')}
                    {!values.insurance_photo_front && (
                      <span className="ml-1 text-red-500">*</span>
                    )}
                  </p>
                  <ImageInput
                    name="insurance_photo_front"
                    className="h-40"
                    previewEnabled={false}
                    required={typeof values.insurance_photo_front !== 'string'}
                  />
                </div>

                <div className="m-1 w-full text-center">
                  <p className="block mt-1 text-sm text-gray-500">
                    {t('Back')}
                    {!values.insurance_photo_back && (
                      <span className="ml-1 text-red-500">*</span>
                    )}
                  </p>
                  <ImageInput
                    name="insurance_photo_back"
                    className="h-40"
                    previewEnabled={false}
                    required={typeof values.insurance_photo_back !== 'string'}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {onUpload && onDelete && (
        <div className="p-8 space-y-6 bg-white rounded-lg">
          <p className="text-xl font-semibold text-grey-forest-900">
            {t('Insurance Review')}
          </p>
          <p className="pb-6 text-base font-normal leading-6 text-grey-forest-600">
            {t(
              "Please upload any additional materials you have related to your health insurance coverage. Specifically we're looking for your Explaination of Benefits packet.",
            )}
          </p>
          <div className="flex flex-col col-span-2 row-start-1 rounded-lg border border-grey-forest-100">
            <div className="border-b border-grey-forest-100">
              <FileDropZone
                onUpload={onUpload}
                disabled={disabled}
                acceptFileTypes="image/*, .pdf, .doc, .docx"
              />
            </div>
            <UploadedFileList
              files={uploadedFiles}
              onDelete={onDelete}
              uploaderIconVisible={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};
