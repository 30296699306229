import type { FC } from 'react';

import { insuranceReviewsUids } from '@nodal/core/consts/insuranceReviewsUids';

import { usePermissions } from 'app/PermissionsProvider';
import { useCandidate } from 'components/CandidateDetails';

import { InsuranceReview } from './InsuranceReview';

const stages = [
  {
    uid: insuranceReviewsUids.donorInsuranceReviewDetails,
    title: 'Insurance Review Details',
  },
  {
    uid: insuranceReviewsUids.donorInsuranceRecords,
    title: 'Insurance Records',
  },
  {
    uid: insuranceReviewsUids.donorInsuranceReviewDecision,
    title: 'Review Decision',
  },
];

export const InsuranceReviewConnected: FC = () => {
  const { permissions } = usePermissions();
  const { candidate } = useCandidate();

  if (!candidate?.role || !permissions) {
    return null;
  }

  return <InsuranceReview stages={stages} />;
};
