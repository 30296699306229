import { useCandidate } from 'components/CandidateDetails/CandidateDetails.connect';

import { InsuranceDetails } from './InsuranceDetails';

export const InsuranceDetailsConnected = () => {
  const { candidate } = useCandidate();
  const insuranceReview = candidate?.profile?.insurance_review;

  if (!insuranceReview) {
    return null;
  }

  return <InsuranceDetails insurance={insuranceReview} />;
};
