import type { FC } from 'react';

import { PlusCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import { t } from '@nodal/i18n';
import { Alert } from '@nodal/uikit/components/Alert';
import { ButtonLoading } from '@nodal/uikit/components/ButtonLoading';
import { TextInput } from '@nodal/uikit/components/TextInput';
import { FieldArray, Form, Formik } from 'formik';

import { useUser } from 'hooks/useUser';

import { useScreening } from '../useScreening';

import type { BackgroundCheckProps } from './BackgroundCheck.interface';

export const BackgroundCheck: FC<BackgroundCheckProps> = ({
  title,
  initialValues,
  onSubmit,
  validationSchema,
  alert,
  description,
  submitted,
}) => {
  const { currentStep, updateCurrentStep, getNextStep } = useScreening();
  const { isDonor, user } = useUser();
  const shouldDisplayNextButton = isDonor && currentStep?.status === 'proc';
  const handleSubmitAndNavigate = async () => {
    try {
      if (!currentStep) return;
      const nextStep = getNextStep();
      if (!nextStep) return;

      if (isDonor) {
        localStorage.setItem(
          'background-check-completed',
          JSON.stringify({
            uid: currentStep.uid,
            type: currentStep.type,
            id: user?.id,
          }),
        );
      }

      updateCurrentStep!({
        ...nextStep,
        index: nextStep.index,
      });
    } catch (error) {
      console.error('Navigation error:', error);
    }
  };

  return (
    <div className="flex flex-col justify-start items-start w-full lg:w-step-view">
      {alert ? (
        <div className="p-4 w-full h-fit bg-white rounded-lg sm:p-8">
          <h2 className="hidden text-2xl font-semibold text-grey-forest-900 sm:block">
            {title}
          </h2>
          <div className="flex flex-col gap-4 pt-6 w-full">
            <Alert {...alert} />
            {isDonor && (
              <ButtonLoading
                type="button"
                variant="primary"
                className="w-full text-base leading-6 sm:w-max"
                onClick={handleSubmitAndNavigate}
              >
                {t('Next')}
              </ButtonLoading>
            )}
          </div>
        </div>
      ) : (
        <div className="p-4 w-full h-fit bg-white rounded-lg sm:p-8">
          <div className="space-y-2">
            <h2 className="hidden text-2xl font-semibold text-grey-forest-900 sm:block">
              {title}
            </h2>
            <p className="text-sm leading-5 text-gray-500">{description}</p>
            {shouldDisplayNextButton && (
              <ButtonLoading
                type="button"
                variant="primary"
                className="w-full text-base leading-6 sm:w-max"
                onClick={handleSubmitAndNavigate}
              >
                {t('Next')}
              </ButtonLoading>
            )}
          </div>
          {!submitted && (
            <div className="pt-6 w-full">
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validationOnChange={false}
                enableReinitialize
              >
                {({ isSubmitting, values }) => (
                  <Form className="flex flex-col gap-6">
                    <TextInput
                      name="primaryEmail"
                      type="text"
                      disabled
                      className="w-full"
                    />
                    {values.partnerEmail !== undefined && (
                      <TextInput
                        name="partnerEmail"
                        type="text"
                        className="w-full"
                        placeholder="Partner email"
                      />
                    )}
                    <FieldArray name="otherEmails">
                      {({ remove, push }) => (
                        <>
                          {values.otherEmails.map((email, index) => {
                            return (
                              <div className="flex gap-4 w-full" key={index}>
                                <TextInput
                                  key={`otherEmails.${index}`}
                                  name={`otherEmails.${index}`}
                                  type="text"
                                  placeholder={'Another email'}
                                  className="w-full"
                                />
                                <button onClick={() => remove(index)}>
                                  <XCircleIcon className="w-6 h-6 text-grey-forest-500" />
                                </button>
                              </div>
                            );
                          })}

                          <button
                            type="button"
                            onClick={() => push('')}
                            className="flex gap-3 items-center py-2 w-max"
                          >
                            <PlusCircleIcon className="w-5 h-5 text-forest-400" />
                            <p className="text-forest-400">
                              {t('Add another email')}
                            </p>
                          </button>
                        </>
                      )}
                    </FieldArray>

                    <ButtonLoading
                      type="submit"
                      variant="primary"
                      className="w-full text-base leading-6 sm:w-max"
                      loading={isSubmitting}
                    >
                      {t('Start Background Check')}
                    </ButtonLoading>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
