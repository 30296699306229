import type { FC, ReactNode } from 'react';

import { apiEnums, useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { screenTypes } from '@nodal/core/consts/screenTypes';
import { LoadingScreen } from '@nodal/uikit/components/LoadingScreen';
import { ModalProvider } from '@nodal/uikit/components/Modal';
import { useEffect, createContext, useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';

import { useFeatureFlags } from 'app/FeatureFlagsProvider';
import { usePermissions } from 'app/PermissionsProvider';
import { settings } from 'settings';
import { canViewCandidateDetails } from 'utils/candidate';

import { appPaths, organizationPaths as paths } from '@b2b/consts/paths';

import { CandidateDetails } from './CandidateDetails';

import type { ApiModel, GlobalError } from '@nodal/api';
import type { ScreenStatusEnum } from '@nodal/api/core';
import type { MedicalReviewsUid } from '@nodal/core/consts/medicalReviewsUids';

const CandidateContext = createContext<{
  candidate?: ApiModel.B2BUser;
  signedDocuments?: ApiModel.EmbeddedDropboxDocument[];
  profile?: ApiModel.ProfileObject;
  ipTasks?: ApiModel.Task[];
  refetchCandidate: () => Promise<unknown>;
}>({
  candidate: undefined,
  signedDocuments: undefined,
  profile: undefined,
  ipTasks: undefined,
  refetchCandidate: () => Promise.resolve(),
});

export const useCandidate = () => {
  const { candidate, signedDocuments, profile, ipTasks, refetchCandidate } =
    useContext(CandidateContext);
  const screens = candidate?.screens;
  const insuranceReviewScreen = screens?.find(
    ({ uid }) => uid === 'insurance-review',
  );

  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const updateMedicalReview = useMutation(
    (
      request: ApiModel.MedicalReviewsApiMedicalReviewsStepPartialUpdateRequest,
    ) =>
      apiClient.api.MedicalReviewsApi.medicalReviewsStepPartialUpdate(request),
    {
      onSuccess: (...[, variables]) => {
        queryClient.invalidateQueries([
          queryKeys.candidateRetrieve,
          variables.userId,
        ]);
      },
    },
  );

  const screenPartialUpdate = useMutation(
    (request: ApiModel.ScreensApiScreensPartialUpdateRequest) =>
      apiClient.api.ScreensApi.screensPartialUpdate(request),
  );

  const onUpdateMedicalReview = async (
    userId: number,
    data: ApiModel.PatchedReviewStep,
    stepUid: MedicalReviewsUid,
  ) => {
    await updateMedicalReview.mutateAsync({
      userId,
      stepUid,
      patchedReviewStep: data,
    });
  };

  const onUpdateInsuranceReview = async (status: ScreenStatusEnum) => {
    if (!insuranceReviewScreen?.id) {
      return;
    }

    await screenPartialUpdate.mutateAsync(
      {
        id: insuranceReviewScreen?.id,
        patchedScreen: {
          status,
        },
      },
      {
        onSuccess: () => {
          refetchCandidate();
        },
      },
    );
  };

  return {
    candidate,
    onUpdateMedicalReview,
    onUpdateInsuranceReview,
    signedDocuments,
    profile,
    ipTasks,
  };
};

export const CandidateDetailsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { id } = useParams() as { id: string };
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const { permissions } = usePermissions();

  const { data: candidateData, refetch: refetchCandidate } = useQuery(
    [queryKeys.candidateRetrieve, Number.parseInt(id)],
    () => apiClient.api.B2bApi.b2bUsersRetrieve({ id: Number.parseInt(id) }),
    {
      refetchOnMount: false,
      onError: (error: GlobalError) => {
        if (error?.request?.status === 404) {
          navigate(`${paths.app}/${appPaths.candidates}`);
        }
      },
    },
  );

  const { data: candidateProfileData } = useQuery(
    queryKeys.candidateProfileRetrieve,
    () =>
      apiClient.api.B2bApi.b2bUserProfileRetrieve({ id: Number.parseInt(id) }),
  );

  const { id: hipaaConsentFormScreenId } =
    candidateData?.data?.screens.find(
      ({ type }) => type === screenTypes.hipaaConsentForm,
    ) || {};

  const signedDocs = useQuery(
    [queryKeys.usersDocumentsList, Number.parseInt(id)],
    () =>
      apiClient.api.UsersApi.usersDocumentsList({
        userId: candidateData!.data.id,
      }),

    {
      refetchOnMount: false,
      enabled: !!(
        candidateData?.data?.id &&
        candidateData?.data?.medical_review &&
        permissions?.canViewMedicalDocuments &&
        hipaaConsentFormScreenId
      ),
    },
  );

  const { data: b2bIpUserTasksList } = useQuery(
    [queryKeys.b2bUserTasksList, Number.parseInt(id)],
    () =>
      apiClient.api.B2bApi.b2bUserTasksList({ userId: Number.parseInt(id) }),
    {
      // TODO Enable tasks for Surrogates after Dashboard is implemented
      enabled:
        candidateData?.data.role === apiEnums.UserRoleEnum.Par &&
        settings.getIpDashboardFeatureEnabled(),
    },
  );

  if (
    !candidateData?.data ||
    signedDocs.isLoading ||
    !candidateProfileData?.data
  )
    return <LoadingScreen />;

  return (
    <CandidateContext.Provider
      value={{
        candidate: candidateData.data,
        signedDocuments: signedDocs?.data?.data,
        profile: candidateProfileData?.data,
        ipTasks: b2bIpUserTasksList?.data,
        refetchCandidate,
      }}
    >
      {children}
    </CandidateContext.Provider>
  );
};

export const CandidateDetailsRedirect: FC = () => {
  const { candidate } = useCandidate();
  const navigate = useNavigate();
  const { permissions } = usePermissions();
  const { features } = useFeatureFlags();

  useEffect(() => {
    // NOTE: If the candidate does not have a role for which we support the candidate details view,
    // then navigate to the dashboard
    if (
      candidate?.role &&
      permissions &&
      features &&
      !canViewCandidateDetails({
        role: candidate.role,
        canViewAllOrganizations: !!permissions?.canViewAllOrganizations,
        hasAccessToMatching: !!(
          permissions?.canManageMatching && features?.matching
        ),
        hasAccessToNavigator: !!(
          permissions?.canManageNavigator && features?.navigator
        ),
      })
    ) {
      navigate(`${paths.app}/${appPaths.candidates}`);
    }
  }, [candidate?.role, navigate, permissions, features]);

  return <></>;
};

const CandidateDetailsContextConsumer: FC = () => {
  const { candidate } = useCandidate();
  return candidate?.role ? <CandidateDetails role={candidate.role} /> : null;
};

export const CandidateDetailsConnected = () => {
  return (
    <CandidateDetailsProvider>
      <ModalProvider>
        <Routes>
          <Route path="*" element={<CandidateDetailsRedirect />} />
        </Routes>
        <CandidateDetailsContextConsumer />
      </ModalProvider>
    </CandidateDetailsProvider>
  );
};
