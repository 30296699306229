import { ScreenStatusEnum } from '@nodal/api/core';
import { insuranceReviewsUids } from '@nodal/core/consts/insuranceReviewsUids';
import { useState } from 'react';

import { useCandidate } from '@b2b/components/CandidateDetails';

import { InsuranceReviewDecision } from './ReviewDecision';

export const InsuranceReviewDecisionConnected = () => {
  const { candidate, onUpdateInsuranceReview } = useCandidate();
  const reviewDecisionUid = insuranceReviewsUids.donorInsuranceReviewDecision;
  const { status, completion_date } =
    candidate?.screens?.find(({ uid }) => uid === 'insurance-review') || {};
  const [decisionStatus, setDecisionStatus] = useState<ScreenStatusEnum>(
    status || ScreenStatusEnum.Pend,
  );

  if (!reviewDecisionUid || !candidate) {
    return null;
  }

  return (
    <InsuranceReviewDecision
      onChange={async (status) => {
        setDecisionStatus(status);
        await onUpdateInsuranceReview(status);
      }}
      status={decisionStatus}
      completionDate={completion_date ? new Date(completion_date) : undefined}
    />
  );
};
