import type { FC } from 'react';

import { t } from '@nodal/i18n';
import { SelectAsync } from '@nodal/uikit/components/Select';
import { format } from 'date-fns';

import type { InsuranceReviewDecisionProps } from './ReviewDecision.interface';
import type { ScreenStatusEnum } from '@nodal/api/core';

const statusOptions = [
  {
    label: t('Not Started'),
    value: 'pend',
  },
  {
    label: t('Processing'),
    value: 'proc',
  },
  {
    label: t('Complete'),
    value: 'app',
  },
  {
    label: t('Half Complete'),
    value: 'half',
  },
  {
    label: t('Under Review'),
    value: 'rev',
  },
  {
    label: t('Disqualified'),
    value: 'rej',
  },
];

export const InsuranceReviewDecision: FC<InsuranceReviewDecisionProps> = ({
  onChange,
  status,
  completionDate,
}) => {
  return (
    <div className="flex flex-row gap-4 justify-between items-center pb-4 mt-4 border-b border-grey-forest-200 lg:border-none">
      <p className="flex-[0.5] mb-2 text-sm text-center text-grey-forest-700 sm:text-left">
        Insurance Review Decision
      </p>
      <div className="flex flex-row flex-1 gap-4 justify-end items-center">
        <div className="-mt-1 sm:row-start-1">
          <SelectAsync
            onChange={(value) => onChange(value as ScreenStatusEnum)}
            value={status}
            name="status"
            options={statusOptions}
            optionsPosition="top"
            disabled={!status}
            className="w-full max-w-sm"
          />
        </div>
        <p className=" ml-12 text-grey-forest-400 sm:row-start-1">
          {completionDate ? format(completionDate, 'MM/dd/yyyy') : null}
        </p>
      </div>
    </div>
  );
};
