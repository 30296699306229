import { useApiClient } from '@nodal/api';
import { useQuery } from 'react-query';

import { queryKeys } from 'consts/query';

export const useUser = () => {
  const apiClient = useApiClient();
  const { data: userData } = useQuery(queryKeys.usersMeRetrieve, () =>
    apiClient.api.UsersApi.usersMeRetrieve(),
  );

  if (!userData) {
    return {
      user: null,
      isDonor: false,
    };
  }

  const isDonor = userData.data.role === 'dnr';

  return {
    user: userData.data,
    isDonor,
  };
};
