import type { FC } from 'react';

import { FileDropZone } from '@nodal/uikit/components/FileDropZone';
import { UploadedFileList } from '@nodal/uikit/components/UploadedFileList';

import type { InsuranceRecordsProps } from './InsuranceRecords.interface';

export const InsuranceRecords: FC<InsuranceRecordsProps> = ({
  uploaded,
  onUpload,
  onDelete,
  disabled,
}) => {
  return (
    <div className="flex flex-row gap-4 justify-between items-start pb-4 mt-4 border-b border-grey-forest-200 lg:border-none">
      <p className="flex-[0.5] mb-2 text-sm text-center text-grey-forest-700 sm:text-left">
        Insurance Documents
      </p>
      <div className="flex flex-col flex-1 gap-4">
        <div className="border-b border-grey-forest-100">
          <FileDropZone
            onUpload={onUpload}
            disabled={disabled}
            acceptFileTypes="image/*, .pdf, .doc, .docx"
          />
        </div>
        <UploadedFileList
          files={uploaded}
          onDelete={onDelete}
          uploaderIconVisible
        />
      </div>
    </div>
  );
};
