import { apiEnums, useApiClient } from '@nodal/api';
import { t } from '@nodal/i18n';
import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { queryKeys } from 'consts/query';

import type { ApiModel } from '@nodal/api';
import type { ConfirmationOptions } from '@nodal/uikit/components/ConfirmationDialog';
import type { UploadedFile } from '@nodal/uikit/components/UploadedFileList';

export type InsuranceDocumentsControl = {
  uploadedFiles: UploadedFile[];
  disabled: boolean;
  onUpload: (file: File) => Promise<void>;
  onDelete: (id: number) => Promise<void>;
};

const documentTypeToUploader = new Map<
  ApiModel.MedicalDocumentDocumentTypeEnum,
  'organization' | 'user'
>([
  [apiEnums.MedicalDocumentDocumentTypeEnum.ClinicalRecords, 'organization'],
  [apiEnums.MedicalDocumentDocumentTypeEnum.SurroDoc, 'user'],
  [apiEnums.MedicalDocumentDocumentTypeEnum.SurroInsDoc, 'user'],
]);

export const transformDocumentsIntoUploadedFiles = (
  documents: ApiModel.MedicalDocument[],
): UploadedFile[] =>
  documents.map(
    ({
      original_file_name,
      file_size,
      file_type,
      content_url,
      id,
      document_type,
    }) => ({
      name: original_file_name || undefined,
      uploadProgress: 1,
      id: id,
      size: file_size ? Number.parseInt(file_size) : undefined,
      type: file_type || undefined,
      url: content_url || undefined,
      uploader: documentTypeToUploader.get(document_type),
    }),
  );

export const useUploadInsuranceDocuments = ({
  documents,
  confirm,
  documentType,
  medicalReviewId,
}: {
  documents?: ApiModel.MedicalDocument[];
  confirm: (options: ConfirmationOptions) => Promise<boolean>;
  documentType: ApiModel.MedicalDocumentDocumentTypeEnum;
  medicalReviewId?: number;
}): InsuranceDocumentsControl => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>(
    documents ? transformDocumentsIntoUploadedFiles(documents) : [],
  );
  const queryClient = useQueryClient();
  const apiClient = useApiClient();

  const uploadInsuranceDocument = useMutation(
    (
      requestParameters: ApiModel.MedicalReviewsApiMedicalReviewsInsuranceDocumentCreateCreateRequest,
    ) =>
      apiClient.api.MedicalReviewsApi.medicalReviewsInsuranceDocumentsCreateCreate(
        requestParameters,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (e) => {
            setUploadedFiles((prevFiles) =>
              prevFiles.map((file) =>
                file.id === requestParameters.insuranceDocumentCreate.id &&
                e.total
                  ? { ...file, uploadProgress: e.loaded / e.total }
                  : file,
              ),
            );
          },
        },
      ),
    {
      onSuccess: (response) => {
        const previousData = queryClient.getQueryData<{
          data: ApiModel.MedicalDocument[];
        }>(queryKeys.insuranceDocumentsList);

        queryClient.setQueryData(queryKeys.insuranceDocumentsList, {
          ...previousData,
          data: [...(previousData?.data || []), response.data],
        });
        setUploadedFiles((prevFiles) => [
          ...prevFiles,
          transformDocumentsIntoUploadedFiles([response.data])[0],
        ]);
      },
    },
  );

  const removeInsuranceDocument = useMutation(
    (
      requestParameters: ApiModel.MedicalReviewsApiMedicalReviewsInsuranceDocumentDeleteDestroyRequest,
    ) =>
      apiClient.api.MedicalReviewsApi.medicalReviewsInsuranceDocumentsDeleteDestroy(
        requestParameters,
      ),
    {
      onError: () => {
        toast.error(t('Something went wrong'));
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      onSuccess: (_, requestParameters) => {
        const previousData = queryClient.getQueryData<{
          data: ApiModel.MedicalDocument[];
        }>(queryKeys.insuranceDocumentsList);
        const newData = previousData?.data.filter(
          (doc) => doc.id !== requestParameters.id,
        );
        queryClient.setQueryData(queryKeys.insuranceDocumentsList, {
          data: newData,
        });
        setUploadedFiles((prevFiles) =>
          prevFiles.filter((file) => file.id !== requestParameters.id),
        );
      },
    },
  );

  const onDeleteFile = useCallback(
    async (id: number) => {
      const confirmed = await confirm({
        title: t('Delete Record'),
        message: t(
          'Are you sure you want to delete this record? This action cannot be undone and will remove it from the clinic records.',
        ),
        variant: 'alert',
      });

      if (confirmed) {
        await removeInsuranceDocument.mutateAsync({
          id,
        });
      }
    },
    [confirm, removeInsuranceDocument],
  );

  const onUploadFile = useCallback(
    async (data: File) => {
      const fileId = Date.now() + Math.random();

      try {
        await uploadInsuranceDocument.mutateAsync({
          insuranceDocumentCreate: {
            medical_review: medicalReviewId!,
            document_type: documentType,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            content: data,
            id: fileId,
          },
        });
      } catch (error) {
        setUploadedFiles((prevFiles) =>
          prevFiles.map((file) =>
            file.id === fileId
              ? { ...file, error: t('Error while uploading the file') }
              : file,
          ),
        );
      }
    },
    [documentType, uploadInsuranceDocument, medicalReviewId],
  );

  return {
    onUpload: onUploadFile,
    onDelete: onDeleteFile,
    uploadedFiles,

    disabled:
      !!uploadedFiles.find((file) => file.uploadProgress !== 1) ||
      !medicalReviewId,
  };
};
